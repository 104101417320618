import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../static/general/isolezwe.svg'
import { Button } from 'semantic-ui-react'
import { MenuIco, Search, Twitter, Facebook, Instagram, RSS as Rss } from 'ion-icon'
import { defaultWidth, DesktopTablet, Mobile } from 'ion-media'
// import Ad from '../Ad'
// import FORMATS from '../AdFormats'

export default class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isSearchOpen: false
    }
  }

  render () {
    return (
      <header role='banner'>
        <a href='#main-content' className='show-on-focus'>
          Skip to content
        </a>
        <div className='wrapper'>
          <nav className='commercial-nav'>
            <ul>
              <li><a rel='noopener noreferrer' href='https://www.iol.co.za' target='_blank' title='IOL' id='iol-commerical-nav'>IOL</a></li>
              <li><a rel='noopener noreferrer' href='http://www.loot.co.za' target='_blank' title='Loot' id='young-independent-commerical-nav'>Shop @ Loot</a></li>
              <li><a href='https://www.stylemode.co.za' title='StyleMode' target='_blank' rel='noopener noreferrer nofollow'>StyleMode</a></li>
              <li><a rel='noopener noreferrer' href='http://www.property360.co.za?referrer=isol' target='_blank' title='Property360' id='iol-property-commerical-nav'>Property360</a></li>
              <li><a rel='noopener noreferrer' href='https://www.voices360.co.za' target='_blank' title='Voices360' id='voices360-commerical-nav'>Voices360</a></li>
              <li><a rel='noopener noreferrer' href='https://blog.talent360.co.za/' target='_blank' title='Talent360' id='talent360-commerical-nav'>Talent360</a></li>
            </ul>
            <div>{new Date().toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })}</div>
          </nav>
        </div>
        <div className='border-bottom'>
          <div className='wrapper'>
            <Mobile values={{ deviceWidth: defaultWidth }}>
              <Button className='menu-btn' onClick={() => this.props.toggleMenu()}><MenuIco width='24' height='24' /></Button>
            </Mobile>
            <div className='logo-search'>
              <Link to='/' className='logo'>
                <img src={logo} alt={process.env.RAZZLE_SITE_NAME} />
              </Link>
              <form action='/search' method='get' className='search-form'>
                <input type='search' placeholder={'Search ' + process.env.RAZZLE_SITE_NAME} name='q' id='q' />
                <button><Search width='16' height='16' /></button>
              </form>
              <div className='header-social'>
                <a href={process.env.RAZZLE_SITE_FACEBOOK} className='facebook' target='_blank' rel='noopener noreferrer'>
                  <Facebook width='24' height='24' />
                </a>
                <a href={process.env.RAZZLE_SITE_TWITTER} className='twitter' target='_blank' rel='noopener noreferrer'>
                  <Twitter width='24' height='24' fill='#1D1D1D' />
                </a>
                <a href={process.env.RAZZLE_SITE_INSTAGRAM} className='instagram' target='_blank' rel='noopener noreferrer'>
                  <Instagram width='24' height='24' />
                </a>
                <a href={process.env.RAZZLE_SITE_RSS} className='rss' target='_blank' rel='noopener noreferrer'>
                  <Rss width='24' height='24' />
                </a>
              </div>
            </div>
          </div>
        </div>
        <DesktopTablet values={{ deviceWidth: defaultWidth }}>
          <div className='wrapper'>
            <nav>
              <ul>
                <li>
                  <Link to='/'>Home</Link>
                </li>
                <li>
                  <Link to='/izindaba'>Izindaba</Link>
                </li>
                <li>
                  <Link to='/ezokungcebeleka'>Ezokungcebeleka</Link>
                  <ul className='menu-articles'>
                    <li>
                      <Link to='/ezokungcebeleka/ezemisakazo'>Ezemisakazo</Link>
                    </li>
                    <li>
                      <Link to='/ezokungcebeleka/ezethelevishini'>Ezethelevishini</Link>
                    </li>
                    <li>
                      <Link to='/ezokungcebeleka/osaziwayo'>Osaziwayo</Link>
                    </li>
                    <li>
                      <Link to='/ezokungcebeleka/umculo'>Umculo</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/ezemidlalo'>Ezemidlalo</Link>
                  <ul className='menu-articles'>
                    <li>
                      <Link to='/ezemidlalo/ibhola'>Ibhola</Link>
                      <ul className='sub-mnu-nav'>
                        <li>
                          <Link to='/ezemidlalo/ibhola/upotshoza'>Upotshoza</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to='/ezemidlalo/isigcawu-sabalandeli'>Isigcawu Sabalandeli</Link>
                    </li>
                    <li>
                      <Link to='/ezemidlalo/ezinye-ezemidlalo'>Ezinye Ezemidlalo</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to='/imibono'>Imibono</Link>
                </li>
                <li>
                  <Link to='/ezokuvakasha'>Ezokuvakasha</Link>
                  <ul className='menu-articles'>
                    <li><Link to='/ezokuvakasha/ezemfashini-nezokuzicwala'>Ezemfashini nezokuzicwala</Link></li>
                    <li><Link to='/ezokuvakasha/phola-ekzn'>Phola eKZN</Link></li>
                    <li><Link to='/ezokuvakasha/izithombe'>Izithombe</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to='/impilo-yabantu/ezezimoto'>Ezezimoto</Link>
                </li>
                <li>
                  <Link to='/impilo-yabantu'>Impilo Yabantu</Link>
                  <ul className='menu-articles'>
                    <li>
                      <Link to='/impilo-yabantu/intandokazi'>Intandokazi</Link>
                    </li>
                    <li>
                      <Link to='/impilo-yabantu/ezothando'>Ezothando</Link>
                    </li>
                    <li>
                      <Link to='/impilo-yabantu/ezempilo'>Ezempilo</Link>
                    </li>
                    <li>
                      <Link to='/impilo-yabantu/whatsfordinner'>Whatsfordinner</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </DesktopTablet>
      </header>
    )
  }
}
