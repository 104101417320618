import React from 'react'
import { NavLink } from 'ion-navchevron'
import 'semantic-ui-css/components/menu.min.css'

const NavMenu = ({ toggleMenu }) => (
  // eslint-disable-next-line
  <ul className='main-nav'>
    <li>
      <NavLink to='/' exact>Home</NavLink>
    </li>
    <li>
      <NavLink to='/izindaba'>Izindaba</NavLink>
    </li>
    <li>
      <NavLink to='/ezokungcebeleka'>Ezokungcebeleka</NavLink>
      <ul className='sub-nav'>
        <li>
          <NavLink to='/ezokungcebeleka/ezemisakazo'>Ezemisakazo</NavLink>
        </li>
        <li>
          <NavLink to='/ezokungcebeleka/ezethelevishini'>Ezethelevishini</NavLink>
        </li>
        <li>
          <NavLink to='/ezokungcebeleka/osaziwayo'>Osaziwayo</NavLink>
        </li>
        <li>
          <NavLink to='/ezokungcebeleka/umculo'>Umculo</NavLink>
        </li>
      </ul>
    </li>
    <li>
      <NavLink to='/ezemidlalo'>Ezemidlalo</NavLink>
      <ul className='sub-nav'>
        <li>
          <NavLink to='/ezemidlalo/ibhola'>Ibhola</NavLink>
          <ul className='sub-nav'>
            <li>
              <NavLink to='/ezemidlalo/ibhola/upotshoza'>Upotshoza</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to='/ezemidlalo/isigcawu-sabalandeli'>Isigcawu Sabalandeli</NavLink>
        </li>
        <li>
          <NavLink to='/ezemidlalo/ezinye-ezemidlalo'>Ezinye Ezemidlalo</NavLink>
        </li>
      </ul>
    </li>
    <li>
      <NavLink to='/imibono'>Imibono</NavLink>
    </li>
    <li>
      <NavLink to='/ezokuvakasha'>Ezokuvakasha</NavLink>
      <ul className='sub-nav'>
        <li><NavLink to='/ezokuvakasha/ezemfashini-nezokuzicwala'>Ezemfashini Nezokuzicwala</NavLink></li>
        <li><NavLink to='/ezokuvakasha/phola-ekzn'>Phola eKZN</NavLink></li>
        <li><NavLink to='/ezokuvakasha/izithombe'>Izithombe</NavLink></li>
      </ul>
    </li>
    <li>
      <NavLink to='/impilo-yabantu/ezezimoto'>Ezezimoto</NavLink>
    </li>
    <li>
      <NavLink to='/impilo-yabantu'>Impilo Yabantu</NavLink>
      <ul className='sub-nav'>
        <li>
          <NavLink to='/impilo-yabantu/intandokazi'>Intandokazi</NavLink>
        </li>
        <li>
          <NavLink to='/impilo-yabantu/ezothando'>Ezothando</NavLink>
        </li>
        <li>
          <NavLink to='/impilo-yabantu/ezempilo'>Ezempilo</NavLink>
        </li>
        <li>
          <NavLink to='/impilo-yabantu/whatsfordinner'>Whatsfordinner</NavLink>
        </li>
      </ul>
    </li>
  </ul>
)

export default NavMenu
