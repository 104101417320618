import React from 'react'
import NavMenu from './NavMenu'
import { Facebook, Twitter, Instagram, Close, Search } from 'ion-icon'

const MobileMenu = ({ toggleMenu }) => (
  <nav role='navigation'>
    <button className='close-btn' onClick={toggleMenu}><Close width='20' height='20' /></button>
    <ul className='menu-social'>
      <li>
        <a href={process.env.RAZZLE_SITE_FACEBOOK} target='_blank' rel='noopener noreferrer' title={'Like ' + process.env.RAZZLE_SITE_NAME + ' on Facebook'}>
          <Facebook width='30' height='30' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_TWITTER} target='_blank' rel='noopener noreferrer' title={'Follow ' + process.env.RAZZLE_SITE_NAME + ' on Twitter'}>
          <Twitter width='30' height='30' fill='white' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_INSTAGRAM} target='_blank' rel='noopener noreferrer' title={'Follow ' + process.env.RAZZLE_SITE_NAME + ' on Instagram'}>
          <Instagram width='30' height='30' />
        </a>
      </li>
    </ul>
    <div className='mobile-nav-search'>
      <form action='/search' method='get'>
        <input type='search' placeholder={'Search ' + process.env.RAZZLE_SITE_NAME} name='q' id='q' />
        <button><Search width='20' height='20' /></button>
      </form>
    </div>
    <NavMenu />
  </nav>
)

export default MobileMenu
