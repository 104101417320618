import { compose, createStore, applyMiddleware } from 'redux'
import createSagaMiddleware, { END } from 'redux-saga'
import createSagaMonitor from '@clarketm/saga-monitor'

// Beacons
import { createMiddleware as createBeaconMiddleware } from 'redux-beacon'
import GoogleTagManager from '@redux-beacon/google-tag-manager'
import OfflineWeb from '@redux-beacon/offline-web'
import IONAnalytics from 'redux-beacon-IONAnalytics'
import Outbrain from 'redux-beacon-Outbrain'

import { eventsMap } from './events'
import rootReducer from './rootReducer'
import { isConnected } from './app'

const gtm = GoogleTagManager()
const offlineStorage = OfflineWeb(isConnected)

function beaconMiddleware (isServer) {
  if (!isServer) {
    return [
      createBeaconMiddleware(eventsMap, gtm, { offlineStorage }),
      createBeaconMiddleware(eventsMap, Outbrain),
      createBeaconMiddleware(eventsMap, IONAnalytics, { offlineStorage })
    ]
  } else {
    return [
      createBeaconMiddleware(eventsMap, IONAnalytics)
    ]
  }
}

export default function createReduxStore (state = {}) {
  const sagaMiddleware = process.env.RAZZLE_SAGA_MONITOR === 'on'
    ? createSagaMiddleware({
      sagaMonitor: createSagaMonitor({
        level: 'warn',
        effectTrigger: true,
        effectResolve: true,
        actionDispatch: true
      })
    })
    : createSagaMiddleware()

  const devToolsCompose = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

  const store = createStore(rootReducer, state, devToolsCompose(applyMiddleware(
    ...beaconMiddleware(typeof window === 'undefined'),
    sagaMiddleware
  )))

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default
      store.replaceReducer(nextRootReducer)
    })
  }

  store.runSaga = sagaMiddleware.run
  store.close = () => store.dispatch(END)

  return store
}
